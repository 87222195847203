import "./Chart.css";
import { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";


const Chart = ({ graph }) => {
  const [chartData, setChartData] = useState([]);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const [date, ...values] = Object.keys(graph.chartData[0]);
    setChartData(graph.chartData);
    setFields(values);
    setTimeout(() => {
      // addData();
    }, 2000);
  }, [graph]);

  const addData  = () => {
    setInterval(() => {
      const data = {};
     
      const keys = Object.keys(graph.chartData[0]);
      keys.map((key) => (data[`${key}`] = Math.floor(Math.random() * 10000)));
      setChartData(([f, ...d]) => {
        return [...d, data];
      });
    }, 3000);
  };
  return (
    <div>
      <h5>{graph.name}</h5>
      <AreaChart
        width={1502}
        height={506}
        data={chartData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          {fields.map((value, i) => {
            if (i % 2 === 0) {
              return (
                <linearGradient id={value} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#AE2FB0" stopOpacity={0.2} />
                  <stop offset="95%" stopColor="#AE2FB0" stopOpacity={0} />
                </linearGradient>
              );
            } else {
              return (
                <linearGradient id={value} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#322FB0" stopOpacity={0.2} />
                <stop offset="95%" stopColor="#322FB0" stopOpacity={0} />
              </linearGradient>
              );
            }
          })}
        </defs>
        <XAxis dataKey="date" tickLine={false} />
        <YAxis
          type="number"
          domain={[0, 12000]}
          tickCount={10}
          axisLine={false}
        />
        <CartesianGrid strokeDasharray="10 10" vertical={false} />
        <Tooltip />
        {fields.map((value, i) => {
          if (i % 2 === 0) {
            return (
              <Area
                type="monotone"
                dataKey={value}
                stroke="#AE2FB0"
                fillOpacity={1}
                fill={`url(#${value})`}
                isAnimationActive={false}
                strokeWidth={3}
              />
            );
          } else {
            return (
              <Area
                type="monotone"
                dataKey={value}
                stroke="#322FB0"
                fillOpacity={1}
                fill={`url(#${value})`}
                isAnimationActive={false}
                strokeDasharray="10 10"
                strokeWidth={3}
              />
            );
          }
        })}
      </AreaChart>
    </div>
  );
};

export default Chart;
