import { useState } from "react";

const TabBox = ({cardData,getGraphData}) => {
  const [active, setActive] = useState(null)
  
  return (
    <div>
      {cardData?.length && cardData.map((item) => (
        <div key={item.name} onClick={()=> {getGraphData(item); setActive(item) }} className={`item ${active === item && 'active'}`}>
        <div className="heading1">
            <img src={item.img} alt="mer" />
            <h4>{item.name}</h4>
          </div>
          <div className="heading2">
            <h4>{item.date}</h4>
          </div>
          <div className="heading3">
            <p>{item.rate}</p>
            <span>({item.rateOfPercentage})</span>
          </div>
          <div className="heading2 heading4">
            <h4>{item.perviourDate}</h4>
          </div>
          <div className="heading5">
            <h4>{item.priviourRate}</h4>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TabBox;
