import React from 'react'
import dummypage from "../../Images/dummyPage.svg"

const DummyPage = () => {
  return (
    <div className='select-box'>
       <img src={dummypage} alt="dummypage" />
       <p>Select one of the indicators to see statistics in a graph</p>
    </div>
  )
}

export default DummyPage