import React from 'react'
import Header from '../Components/Header/Header'
import Settingtab from '../Components/Setting/Settingtab'



const Setting = () => {
  return (
    <div>
      <Header>
      <Settingtab/>
      </Header>
    </div>
  )
}

export default Setting
