import mer from "../../Images/mer.svg";
import aov from "../../Images/card_aov.svg";
import sale from "../../Images/card_sale.svg";
import rate from "../../Images/card_rate.svg";
import session from "../../Images/card_session.svg";
import order from "../../Images/card_order.svg";

const DailyFlashData = [
  {
    title: "Prior Day",
    date: "sept 13th",
    desc: "Your sales are down",
    persentage: "10%",
    cardData: [
      {
        name: "MER(ROAS)",
        img: mer,
        date: "sept 14th",
        rate: "5.5",
        rateOfPercentage: "-10%",
        perviourDate: "sept 13th",
        priviourRate: "4.3",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
          },
          {
            date: "08 sun",
            uv: 3000,
          },
          {
            date: "09 mon",
            uv: 2000,
          },
          {
            date: "10 tue",
            uv: 3000,
          },
          {
            date: "11 wed",
            uv: 2500,
          },
          {
            date: "12 thu",
            uv: 2390,
          },
          {
            date: "13 fri",
            uv: 3490,
            
          },
          {
            date: "14 sat",
            uv: 3490,
          },
        ],
      },
      {
        name: "SALES",
        img: sale,
        date: "sept 14th",
        rate: "10000",
        rateOfPercentage: "-10%",
        perviourDate: "sept 13th",
        priviourRate: "11000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
          },
          {
            date: "08 sun",
            uv: 9000,
          },
          {
            date: "09 mon",
            uv: 2000,
          },
          {
            date: "10 tue",
            uv: 2780,
          },
          {
            date: "11 wed",
            uv: 1890,
          },
          {
            date: "12 thu",
            uv: 5000,
          },
          {
            date: "13 fri",
            uv: 5000,
            
          },
          {
            date: "14 sat",
            uv: 3490,
          },
        ],
      },
      {
        name: "ORDERS",
        img: order,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-10%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
          },
          {
            date: "08 sun",
            uv: 5000,
          },
          {
            date: "09 mon",
            uv: 2000,
          },
          {
            date: "10 tue",
            uv: 2780,
          },
          {
            date: "11 wed",
            uv: 1890,
          },
          {
            date: "12 thu",
            uv: 2390,
          },
          {
            date: "13 fri",
            uv: 8490,
            
          },
          {
            date: "14 sat",
            uv: 3490,
          },
        ],
      },
      {
        name: "AOV",
        img: aov,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-10%",
        perviourDate: "sept 13th",
        priviourRate: "110",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
          },
          {
            date: "08 sun",
            uv: 2000,
          },
          {
            date: "09 mon",
            uv: 2000,
          },
          {
            date: "10 tue",
            uv: 2780,
          },
          {
            date: "11 wed",
            uv: 1090,
          },
          {
            date: "12 thu",
            uv: 2390,
          },
          {
            date: "13 fri",
            uv: 3000,
            
          },
          {
            date: "14 sat",
            uv: 3490,
          },
        ],
      },
      {
        name: "SESSIONS",
        img: session,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-10%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
          },
          {
            date: "08 sun",
            uv: 8000,
          },
          {
            date: "09 mon",
            uv: 2000,
          },
          {
            date: "10 tue",
            uv: 2780,
          },
          {
            date: "11 wed",
            uv: 1090,
          },
          {
            date: "12 thu",
            uv: 2390,
          },
          {
            date: "13 fri",
            uv: 3490,
            
          },
          {
            date: "14 sat",
            uv: 1490,
          },
        ],
      },
      {
        name: "CONV RATE",
        img: rate,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-10%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
          },
          {
            date: "08 sun",
            uv: 3000,
          },
          {
            date: "09 mon",
            uv: 1200,
          },
          {
            date: "10 tue",
            uv: 2780,
          },
          {
            date: "11 wed",
            uv: 1890,
          },
          {
            date: "12 thu",
            uv: 2190,
          },
          {
            date: "13 fri",
            uv: 8490,
            
          },
          {
            date: "14 sat",
            uv: 3490,
          },
        ],
      },
      {
        name: "SPEND",
        img: sale,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-10%",
        perviourDate: "sept 13th",
        priviourRate: "100",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
          },
          {
            date: "08 sun",
            uv: 5000,
          },
          {
            date: "09 mon",
            uv: 2000,
          },
          {
            date: "10 tue",
            uv: 1780,
          },
          {
            date: "11 wed",
            uv: 1890,
          },
          {
            date: "12 thu",
            uv: 5590,
          },
          {
            date: "13 fri",
            uv: 3490,
            
          },
          {
            date: "14 sat",
            uv: 3490,
          },
        ],
      },
    ],
  },
  {
    title: "Prior Week",
    date: "sept 7th",
    desc: "Your sales are Up",
    persentage: "2%",
    cardData: [
      {
        name: "MER(ROAS)",
        img: mer,
        date: "sept 14th",
        rate: "5.5",
        rateOfPercentage: "-20%",
        perviourDate: "sept 13th",
        priviourRate: "4.3",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 8000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 3000,
            pv : 4800,            
          },
          {
            date: "10 tue",
            uv: 10780,
            pv: 6908,
           
          },
          {
            date: "11 wed",
            uv: 4890,
            pv : 5810,           
          },
          {
            date: "12 thu",
            uv: 4390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 5800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SALES",
        img: sale,
        date: "sept 14th",
        rate: "10000",
        rateOfPercentage: "-20%",
        perviourDate: "sept 13th",
        priviourRate: "11000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "ORDERS",
        img: order,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-20%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "AOV",
        img: aov,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-20%",
        perviourDate: "sept 13th",
        priviourRate: "110",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SESSIONS",
        img: session,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-20%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "CONV RATE",
        img: rate,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-20%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SPEND",
        img: sale,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-20%",
        perviourDate: "sept 13th",
        priviourRate: "100",
         chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
    ],
  },
  {
    title: "Prior Year",
    date: "sept 13th 2022",
    desc: "Your sales are Up",
    persentage: "10%",
    cardData: [
      {
        name: "MER(ROAS)",
        img: mer,
        date: "sept 14th",
        rate: "5.5",
        rateOfPercentage: "-30%",
        perviourDate: "sept 13th",
        priviourRate: "4.3",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SALES",
        img: sale,
        date: "sept 14th",
        rate: "10000",
        rateOfPercentage: "-30%",
        perviourDate: "sept 13th",
        priviourRate: "11000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "ORDERS",
        img: order,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-30%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "AOV",
        img: aov,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-30%",
        perviourDate: "sept 13th",
        priviourRate: "110",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SESSIONS",
        img: session,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-30%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "CONV RATE",
        img: rate,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-30%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SPEND",
        img: sale,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-30%",
        perviourDate: "sept 13th",
        priviourRate: "100",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
    ],
  },
  {
    title: "Goal",
    date: "Target",
    desc: "Your sales are Up",
    persentage: "47%",
    cardData: [
      {
        name: "MER(ROAS)",
        img: mer,
        date: "sept 14th",
        rate: "5.5",
        rateOfPercentage: "-40%",
        perviourDate: "sept 13th",
        priviourRate: "4.3",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,            
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,            
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,            
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,
           
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,           
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SALES",
        img: sale,
        date: "sept 14th",
        rate: "10000",
        rateOfPercentage: "-40%",
        perviourDate: "sept 13th",
        priviourRate: "11000",
        chartData: [
            {
              date: "07 sat",
              uv: 4000,
              pv: 2400,
            },
            {
              date: "08 sun",
              uv: 3000,
              pv : 1398,
            },
            {
              date: "09 mon",
              uv: 2000,
              pv : 9800,
            },
            {
              date: "10 tue",
              uv: 2780,
              pv: 3908,              
            },
            {
              date: "11 wed",
              uv: 1890,
              pv : 4810,             
            },
            {
              date: "12 thu",
              uv: 2390,
              pv : 3800,              
            },
            {
              date: "13 fri",
              uv: 3490,
              pv : 3800,
            },
            {
              date: "14 sat",
              uv: 3490,
              pv : 4300,
            },
          ],
      },
      {
        name: "ORDERS",
        img: order,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-40%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,              
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,             
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,              
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "AOV",
        img: aov,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-40%",
        perviourDate: "sept 13th",
        priviourRate: "110",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,              
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,             
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,              
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SESSIONS",
        img: session,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-40%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,              
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,             
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,              
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "CONV RATE",
        img: rate,
        date: "sept 14th",
        rate: "1000",
        rateOfPercentage: "-40%",
        perviourDate: "sept 13th",
        priviourRate: "1000",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,              
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,             
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,              
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
      {
        name: "SPEND",
        img: sale,
        date: "sept 14th",
        rate: "100",
        rateOfPercentage: "-40%",
        perviourDate: "sept 13th",
        priviourRate: "100",
        chartData: [
          {
            date: "07 sat",
            uv: 4000,
            pv: 2400,
          },
          {
            date: "08 sun",
            uv: 3000,
            pv : 1398,
          },
          {
            date: "09 mon",
            uv: 2000,
            pv : 9800,
          },
          {
            date: "10 tue",
            uv: 2780,
            pv: 3908,              
          },
          {
            date: "11 wed",
            uv: 1890,
            pv : 4810,             
          },
          {
            date: "12 thu",
            uv: 2390,
            pv : 3800,              
          },
          {
            date: "13 fri",
            uv: 3490,
            pv : 3800,
          },
          {
            date: "14 sat",
            uv: 3490,
            pv : 4300,
          },
        ],
      },
    ],
  },
];
export default DailyFlashData;
