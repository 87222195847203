
import Header from '../Components/Header/Header'
import Live_view from '../Components/LiveView/Live_view'

const LiveView = () => {
  return (
    <div>
      <Header>
        <Live_view/>
      </Header>
    </div>
  )
}

export default LiveView
