import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, ButtonToolbar, Form, Nav } from "react-bootstrap";
import { BiTrendingDown } from "react-icons/bi";
import { BiTrendingUp } from "react-icons/bi";
import priorday1 from "../../Images/priorday1.svg";
import priorday2 from "../../Images/priorday2.svg";
import TabBox from "../tabs/TabBox";
import Chart from "../Charts/Chart";
import WindowDimensions from "../tabs/WindowDimensions";
import "./DailyFlashComponent.css";
import DailyFlashData from "../DummyData/DailyFlashData";
import DummyPage from "./DummyPage";
import DatePicker from "react-date-picker";
import moment from "moment/moment";

const DailyFlashComponent = () => {
  const [selectTab, setSectectTab] = useState({});
  const [toggleState, setToggleState] = useState(1);
  const [mobileButton, setMobileButton] = useState("card"); // card, chart
  const { height, width } = WindowDimensions();
  const [graph, setGraph] = useState({});
  const [selectedDate, onChangeDate] = useState(new Date());
  
  // useEffect(() => {
  //   let formattedDate = moment(selectedDate).format("YYYY-MM-DD");
  // }, [selectedDate]);
  console.log(selectedDate)

  useEffect(() => {
    const getActiveTab = DailyFlashData[0].title;
    Findtab(getActiveTab);
    toggleTab(0);
  }, []);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  const Findtab = (value) => {
    const apidata = DailyFlashData.find((data) => data.title === value);
    setSectectTab(apidata);
  };
  const getGraphData = (value) => {
    setGraph(value);
  };

  return (
    <div className="daily-flash-tab">
      <div className="main-heading d-sm-flex d-block justify-content-between">
        <h1>Daily Flash</h1>
        <div className="date">
          {/* <Form className="">
            <input type="date" value="2017-06-01" className="user-input" />
          </Form> */}
          <DatePicker
            onChange={onChangeDate}
            value={selectedDate}
            format="y-MM-d"
          />
        </div>
      </div>
      <div className="tabs-main">
        <Nav variant="tabs" onSelect={(selected) => Findtab(selected)}>
          {DailyFlashData.map((e, i) => (
            <Nav.Item key={i}>
              <Nav.Link
                onClick={() => toggleTab(i)}
                eventKey={e.title}
                className={
                  toggleState === i
                    ? "tabs-main nav-link active"
                    : "tabs-main nav-link"
                }
              >
                <h4>
                  {e.title} <span>: {e.date}</span>
                </h4>
                <div className="down-up-text">
                  <p>{e.desc}</p>
                  <span className="down-text">
                    <BiTrendingDown />
                    <p>{e.persentage}</p>
                  </span>
                </div>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div className="mob-heading">
        <div className="heading-left">
          <h4>Sept 13th</h4>
          <div className="down-up-text">
            <p>Your sales are down</p>
            <span className="down-text">
              <BiTrendingDown />
              <p>10%</p>
            </span>
          </div>
        </div>
        <div className="heading-right">
          <ButtonToolbar aria-label="Toolbar with button groups">
            <ButtonGroup className="ml-2" aria-label="First group">
              <Button
                active={mobileButton === "card" ? true : false}
                onClick={(e) => {
                  e.preventDefault();
                  setMobileButton("card");
                }}
              >
                <img src={priorday1} alt="upload" />
              </Button>
              <Button
                active={mobileButton === "chart" ? true : false}
                onClick={(e) => {
                  e.preventDefault();
                  setMobileButton("chart");
                }}
              >
                <img src={priorday2} alt="upload" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </div>
      </div>

      {(mobileButton === "card" || width >= 992) && (
        <div className="tab-box">
          <div className="scroll-left">
            <TabBox cardData={selectTab.cardData} getGraphData={getGraphData} />
          </div>
        </div>
      )}
      {(mobileButton === "chart" || width >= 992) &&
        (graph?.chartData?.length ? (
          <div className="chart-main">
            <Chart graph={graph} />
          </div>
        ) : (
          <DummyPage />
        ))}
    </div>
  );
};

export default DailyFlashComponent;
